import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { useEffect, useState } from "react";

import Layout from "./Components/Layout/Index";
import Utils from "./Common/Utils";
import PrivateRoute from "./Components/ProtectedRoute/PrivateRoute";
import DynamicRedirect from "./Components/Redirect/DynamicRedirect";
import RoleProvider from "./Contexts/Providers/RoleProvider";
import {
  AccessDenied,
  AuthCallback,
  AuthLogin,
  NoAccessPage,
  SDMChanges,
  Assignments,
  Dashboard,
  ExceptionReporting,
  Explore,
  MissingTransactions,
  MyLinks,
  Notifications,
  SupportData,
  TransactionUploadFile,
  UploadFile,
  ViewAndEditRecord,
  ReviewRecords,
  OnDemandReporting,
  AuditHistory,
  Monitoring,
  FileExplorer
} from "./Pages";

import UserLoginProvider from "./Contexts/Providers/UserLoginProvider";

function App() {
  let oldstoreview = localStorage.getItem("oldstoreview")
  const [path, setPath] = useState([
    "/",
    "/dashboard",
    "/auth/noaccess",
    "/sdm",
    "/view/:tablename/:id",
    "/fileupload",
    "/supportdata",
    "/explore",
    "/assignments",
    "/notifications",
    "/missingtransactions",
    "/transactionsfileupload",
    "/transactionsfileupload",
    "/exceptionreporting",
    "/mylinks",
    "/reviewrecords/:tablename/:id",
    "/ondemandreporting",
    "/audithistory",
    "/monitoring",
    "/lockedview",
    "/fileexplorer"
  ]);

  // it will check if environment is production or uat then we can't access /dashboard/old for others we allowed
  useEffect(() => {
    const env: string = Utils.getCurrentEnv();
    if (!['prod', 'uat'].includes(env)) {
      setPath([...path, "/dashboard/old"])
    }
  }, []);

  return (
    <Router>
      {/* <Redirect exact from="/" to="/dashboard" /> */}
      <Route
        exact
        path={path}
      >
        <UserLoginProvider>
          <RoleProvider>
            {/* Pass Roles to which each route should be available */}
            <Switch>
              <Route exact path="/" component={DynamicRedirect} />
              <PrivateRoute
                from="dashboard"
                roles={[
                  "SUPER_USER",
                  "DATA_ANALYST",
                  "GTITADMIN_SUPPORT",
                  "NATC",
                  "PUBLICATION_LEAD",
                  "MANAGEMENT_LEADERSHIP",
                ]}
                path="/dashboard"
                exact={true}
                component={Dashboard}
              />
              {/* router added for old dashboard view */}
              <PrivateRoute
                roles={[
                  "SUPER_USER",
                  "DATA_ANALYST",
                  "GTITADMIN_SUPPORT",
                  "NATC",
                  "PUBLICATION_LEAD",
                  "MANAGEMENT_LEADERSHIP",
                ]}
                path="/dashboard/old"
                exact={true}
                component={Dashboard}
              />
              <PrivateRoute
                roles={[
                  "SUPER_USER",
                  "DATA_ANALYST",
                  "GTITADMIN_SUPPORT",
                  "NATC",
                  "PUBLICATION_LEAD",
                  "MANAGEMENT_LEADERSHIP",
                ]}
                path="/lockedview"
                exact={true}
                component={Dashboard}
              />
              <PrivateRoute
                roles={[
                  "SUPER_USER",
                  "DATA_ANALYST",
                  "GTITADMIN_SUPPORT",
                  "NATC",
                  "PUBLICATION_LEAD",
                  "MANAGEMENT_LEADERSHIP",
                ]}
                path="/sdm"
                exact={true}
                component={SDMChanges}
              />
              <PrivateRoute
                roles={[]}
                path="/auth/noaccess"
                exact={true}
                component={NoAccessPage}
              />
              <PrivateRoute
                roles={[
                  "SUPER_USER",
                  "DATA_ANALYST",
                  "GTITADMIN_SUPPORT",
                  "NATC",
                  "PUBLICATION_LEAD",
                  "MANAGEMENT_LEADERSHIP",
                ]}
                path="/view/:tablename/:id"
                exact={true}
                component={ViewAndEditRecord}
              />
              <PrivateRoute
                roles={[
                  "SUPER_USER",
                  "DATA_ANALYST",
                  "GTITADMIN_SUPPORT",
                  "PUBLICATION_LEAD",
                  "MANAGEMENT_LEADERSHIP",
                ]}
                path="/fileupload"
                exact={true}
                component={UploadFile}
              />
              <PrivateRoute
                roles={[
                  "SUPER_USER",
                  "PUBLICATION_LEAD"
                ]}
                path="/fileexplorer"
                exact={true}
                component={FileExplorer}
              />

              <PrivateRoute
                roles={[
                  "SUPER_USER",
                  "PUBLICATION_LEAD",
                  "MANAGEMENT_LEADERSHIP",
                ]}
                path="/supportdata"
                exact={true}
                component={SupportData}
              />
              <PrivateRoute
                roles={[
                  "SUPER_USER",
                  "DATA_ANALYST",
                  "GTITADMIN_SUPPORT",
                  "NATC",
                  "PUBLICATION_LEAD",
                  "MANAGEMENT_LEADERSHIP",
                ]}
                path="/explore"
                exact={true}
                component={Explore}
              />
              <PrivateRoute
                roles={[
                  "SUPER_USER",
                  "PUBLICATION_LEAD",
                  "MANAGEMENT_LEADERSHIP",
                ]}
                path="/assignments"
                exact={true}
                component={Assignments}
              />

              <PrivateRoute
                roles={[
                  "SUPER_USER",
                  "DATA_ANALYST",
                  "GTITADMIN_SUPPORT",
                  "NATC",
                  "PUBLICATION_LEAD",
                  "MANAGEMENT_LEADERSHIP",
                ]}
                path="/notifications"
                exact={true}
                component={Notifications}
              />
              <PrivateRoute
                roles={[
                  "SUPER_USER",
                  "DATA_ANALYST",
                  "GTITADMIN_SUPPORT",
                  "PUBLICATION_LEAD",
                  "MANAGEMENT_LEADERSHIP",
                ]}
                path="/transactionsfileupload"
                exact={true}
                component={TransactionUploadFile}
              />
              <PrivateRoute
                roles={[
                  "SUPER_USER",
                  "DATA_ANALYST",
                  "GTITADMIN_SUPPORT",
                  "NATC",
                  "PUBLICATION_LEAD",
                  "MANAGEMENT_LEADERSHIP",
                ]}
                path="/mylinks"
                exact={true}
                component={MyLinks}
              />
              <PrivateRoute
                roles={[
                  "SUPER_USER",
                  "PUBLICATION_LEAD",
                  "MANAGEMENT_LEADERSHIP",
                ]}
                path="/reviewrecords/:tablename/:id"
                exact={true}
                component={ReviewRecords}
              />
              {/* Start ==== PPSMED-3380 Keep the Phase 1 . Feature on PPS Nov release #deletelater ==== */}
              {oldstoreview === 'true' ? <>
                <PrivateRoute
                  roles={[
                    "SUPER_USER",
                    "DATA_ANALYST",
                    "GTITADMIN_SUPPORT",
                    "NATC",
                    "PUBLICATION_LEAD",
                    "MANAGEMENT_LEADERSHIP",
                  ]}
                  path="/missingtransactions"
                  exact={true}
                  component={MissingTransactions}
                />
                <PrivateRoute
                  roles={[
                    "SUPER_USER",
                    "DATA_ANALYST",
                    "PUBLICATION_LEAD",
                    "MANAGEMENT_LEADERSHIP",
                  ]}
                  path="/exceptionreporting"
                  exact={true}
                  component={ExceptionReporting}
                /><PrivateRoute
                  roles={["SUPER_USER", "PUBLICATION_LEAD"]}
                  path="/ondemandreporting"
                  exact={true}
                  component={OnDemandReporting}
                /><PrivateRoute
                  roles={[
                    "SUPER_USER",
                    "DATA_ANALYST",
                    "GTITADMIN_SUPPORT",
                    "NATC",
                    "PUBLICATION_LEAD",
                    "MANAGEMENT_LEADERSHIP",
                  ]}
                  path="/audithistory"
                  exact={true}
                  component={AuditHistory}
                /> <PrivateRoute
                  roles={[
                    "SUPER_USER",
                    "DATA_ANALYST",
                    "GTITADMIN_SUPPORT",
                    "NATC",
                    "PUBLICATION_LEAD",
                    "MANAGEMENT_LEADERSHIP",
                  ]}
                  path="/monitoring"
                  exact={true}
                  component={Monitoring}
                /></> : <></>}
              {/* ===== PPSMED-3380 Keep the Phase 1 . Feature on PPS Nov release #deletelater ==== end */}
            </Switch>
          </RoleProvider>
        </UserLoginProvider>
      </Route>
      <Route
        exact
        path={["/auth/login", "/auth/callback", "/auth/accessdenied"]}
      >
        <UserLoginProvider>
          <Layout showMenu={false} showTableMenu={false}>
            <Switch>
              <Route path="/auth/login" component={AuthLogin} />
              <Route path="/auth/callback" component={AuthCallback} />
              <Route path="/auth/accessdenied" exact component={AccessDenied} />
            </Switch>
          </Layout>
        </UserLoginProvider>
      </Route>
    </Router>
  );
}

export default App;
