import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import FilterListIcon from "@mui/icons-material/FilterList";
import PublishIcon from "@mui/icons-material/Publish";
import moment from "moment";
import {
  AlertColor,
  Box,
  Chip,
  CircularProgress,
  Grid,
  InputLabel,
  Link,
  Popover,
  Typography,
} from "@mui/material";
import _, { pick } from "lodash";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { sdmTemplate } from "../../Common/Constants";
import { CustomEventType, ValidationType } from "../../Common/Enums";
import Utils from "../../Common/Utils";
import GradientButton from "../../Components/Button/GradientButton";
import IconButton, { IconType } from "../../Components/Button/IconButton";
import RedOutlinedButton from "../../Components/Button/RedOutlinedButton";
import CardContainer from "../../Components/Card/CardContainer";
import ModalDialog from "../../Components/Modal/ModelDialog";
import SelectModel from "../../Components/Select/SelectModel";
import SingleSelect from "../../Components/Select/SingleSelect";
import SnackbarAlert from "../../Components/SnackBarAlert/SnackbarAlert";
import DataLoader from "../../Components/Table/DataLoader";
import PageHeader from "../../Components/Text/PageHeader";
import { RoleContext } from "../../Contexts";
import { IToolbarMode } from "../../Models/IToolbarMode";
import { AccessedSDMTable } from "../../Models/SDMTableAccessModel";
import {
  GetColumnDetailsRequestModel,
  GetColumnDetailsResponseModel,
  GetSDMTableRequestModel,
  KeyTablesModel,
  SDMRecordPayloadModel,
} from "../../Models/SDMTableRequestModel";
import AuthProviderService from "../../Services/AuthProviderService";
import ExcelService from "../../Services/ExcelService";
import SDMTableService from "../../Services/SDMTableService";
import UserAuthService from "../../Services/UserAuthService";
import FilterColumns from "./FilterColumn";

import { SDM_DATE_COLUMNS } from "../../Common/Constants";
const SDMTableServices = new SDMTableService();
type tableModeForEntries =
  | "multipleTableEntries"
  | "SingleTableEntries"
  | "view"
  | undefined;
interface Props extends RouteComponentProps<any, any, any> {
  commentClick: (record: any) => void;
  record: any;
  commentId: any;
  tableModeForEntries: tableModeForEntries;
  validationType: ValidationType;
  isRequired?: boolean;
  /* handleSearchChange : (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=> void ; */
}

interface State {
  mode: IToolbarMode;
  modes: tableModeForEntries;
  filterCol: { fieldName: string; checked: boolean }[];
  headcellData: any[];
  tblData: any[];
  dynamicTable: any[];
  rows: number;
  page: number;
  search: string;
  showDeleteDialoge: boolean;
  selectedSdmTable: any;
  editIcoId: number | undefined;
  addComment: boolean;
  showCancelbtn: boolean;
  record: any;
  commentId: any;
  commentForId: any;
  selectedCountry: string;
  bulkEntries: boolean;
  accessedSdmTableData: AccessedSDMTable[];
  roleData: any;
  selectedSdmTableValue: string;
  totalRecordCount: number;
  checked: boolean;
  filteredColData: any[];
  isLoading: boolean;
  isSDMListLoading: boolean;
  uniqueColumnObj: any;
  uniqueColumnValue: string;
  isOpenFilterByCol: boolean;
  columnDetails: GetColumnDetailsResponseModel[];
  AllColumnDetails: GetColumnDetailsResponseModel[];
  tableCellData: any;
  isResetAllData: boolean;
  isReset: boolean;
  bulkEditInputFormData: any;
  checkedTableData: any;
  selectedEntry: string;
  bulkInsertState: any;
  formValidationState: any;
  userId: string;
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  isButtonLoad: boolean;
  singleTableData: any;
  rationale: string;
  searchText: string;
  filterData: any;
  isPaginationDisabled: boolean;
  keyTableData: any;
  selectedKeyTable: string;
  isEventListner: boolean;
  isPaginationReset: boolean;
  userRoles: any;
  isEmptyReset: boolean;
  validation: boolean;
  isdataFiltered: boolean;
  filteredColumn: { column: any; value: string; type: any };
  sortedColumn: string;
  sortedDirection: string;
  isTableDataLoading: boolean;
}

class SdmChanges extends Component<Props, State> {
  private timer?: NodeJS.Timeout = undefined;
  static contextType = RoleContext;
  context!: React.ContextType<typeof RoleContext>;
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      filterCol: [],
      headcellData: [],
      tblData: [],
      dynamicTable: [],
      rows: 5,
      page: 1,
      search: "",
      showDeleteDialoge: false,
      selectedSdmTable: [],
      editIcoId: undefined,
      addComment: false,
      mode: "view",
      modes: undefined,
      showCancelbtn: false,
      record: [],
      commentId: undefined,
      commentForId: undefined,
      selectedCountry: "",
      bulkEntries: false,
      accessedSdmTableData: [],
      roleData: [],
      selectedSdmTableValue: "",
      totalRecordCount: 0,
      checked: false,
      filteredColData: [],
      isLoading: false,
      isSDMListLoading: false,
      uniqueColumnObj: {},
      uniqueColumnValue: "",
      isOpenFilterByCol: false,
      columnDetails: [],
      AllColumnDetails: [],
      tableCellData: [],
      isResetAllData: false,
      isReset: false,
      bulkEditInputFormData: {},
      checkedTableData: [],
      selectedEntry: "",
      bulkInsertState: [],
      formValidationState: {},
      userId: "",
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      isButtonLoad: false,
      singleTableData: [],
      rationale: "",
      searchText: "",
      filterData: [],
      isPaginationDisabled: false,
      keyTableData: [],
      selectedKeyTable: "",
      isEventListner: false,
      isPaginationReset: false,
      userRoles: [],
      isEmptyReset: false,
      validation: false,
      isdataFiltered: false,
      filteredColumn: { column: "", value: "", type: "" },
      sortedColumn: "",
      sortedDirection: "",
      isTableDataLoading: false,
    };
    document.addEventListener(
      CustomEventType[CustomEventType.ChangeTable],
      async (value: any) => {
        this.handleMenuChange(value.detail.value);

        try {
          const roleValue = localStorage.getItem("Role");
          if (roleValue) {
            await this.getKeyTables(roleValue);
          } else {
            await this.getKeyTables(value.detail.roleValue);
          }
        } catch (error) {
          console.error(error);
        }
      }
    );
  }

  async componentDidMount() {
    if (this.context.accessedTables.length > 0) {
      this.setState({ isSDMListLoading: true });
      const token = await AuthProviderService.getAccessToken();
      const decodedToken = JSON.stringify(Utils.decodeJWT(token.accessToken));
      const userId = JSON.parse(decodedToken).uid;
      const userRole = await UserAuthService.fetchUserRoles();

      const data = this.context.accessedTables.map((el: any) => {
        return {
          roleAssigned: el.roleAssigned,
          tables: el.tables.map((val: any) => {
            return { text: val, value: val };
          }),
        };
      });

      let defaultTable = data
        .map((el: any) => el.tables)
        .map((el: any) => el[0])[0].text;

      this.setState(
        {
          isSDMListLoading: false,
          uniqueColumnObj: this.context.uniqueColumnObj,
          selectedSdmTableValue: defaultTable,
          uniqueColumnValue: this.context.uniqueColumnObj[defaultTable],
          userId,
          userRoles: userRole.data.data.data.roles,
        },
        async () => {
          const sdmTableValue = localStorage.getItem("sdmTable");
          const keyTableValue = localStorage.getItem("keyTable");
          if (sdmTableValue) {
            this.handleMenuChange(sdmTableValue);
          } else if (keyTableValue) {
            this.handleMenuChange(keyTableValue);
          } else {
            this.handleMenuChange(defaultTable);
          }
          const roleValue = localStorage.getItem("Role");

          if (roleValue) {
            const keyTablePayload: KeyTablesModel = {
              var_list: "",
              var_persona: roleValue,
            };
            const keyTableData = await SDMTableServices.getKeyTables(
              keyTablePayload
            );
            if (JSON.parse(keyTableData.result)) {
              const keyTableResponse = JSON.parse(keyTableData.result).map(
                (val: any) => {
                  return {
                    text: val.table_name.toUpperCase(),
                    value: val.table_name.toUpperCase(),
                  };
                }
              );

              this.setState({
                keyTableData: keyTableResponse,
                selectedKeyTable: keyTableValue ? keyTableValue : "",
                searchText: "",
                isPaginationDisabled: false,
              });
            }
          }
        }
      );
    }
  }
  getKeyTables = async (roleValue: string) => {
    try {
      const keyTablePayload: KeyTablesModel = {
        var_list: "",
        var_persona: roleValue,
      };
      const keyTableData = await SDMTableServices.getKeyTables(keyTablePayload);
      if (JSON.parse(keyTableData.result)) {
        const keyTableResponse = JSON.parse(keyTableData.result).map(
          (val: any) => {
            return {
              text: val.table_name.toUpperCase(),
              value: val.table_name.toUpperCase(),
            };
          }
        );
        localStorage.removeItem("keyTable");

        this.setState({
          keyTableData: keyTableResponse,
          selectedKeyTable: "",
          isEventListner: true,
          searchText: "",
          isPaginationDisabled: false,
          isPaginationReset: true,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  componentWillUnmount() {
    document.removeEventListener(
      CustomEventType[CustomEventType.ChangeTable],
      (value: any) => { }
    );
  }
  handleComment = (record: any) => {
    this.props.commentClick(record.id);
  };
  handleFilterColClick = () => {
    this.setState({ isOpenFilterByCol: !this.state.isOpenFilterByCol });
  };

  onChangeRow = (row: number) => {
    this.setState({ rows: row }, () => {
      this.fetchsortedData();
    });
  };

  updateRows=(rows:number)=>{
    this.setState({rows})
  }
  onChangePage = (page: number) => {
    this.setState({ page, rows: this.state.rows }, () => {
      this.fetchsortedData();
    });
  };
  onClickCheckBox = (selectedRows: any, data?: any) => {
    this.setState({
      selectedSdmTable: selectedRows,
      checkedTableData: data,
    });
  };
  handleButtonClick = () => {
    this.setState((prevState) => ({
      addComment: !prevState.addComment,
    }));
  };
  handleFieldReset = (event: any, type: any) => {
    event.preventDefault();
    if (type === "bulkformreset") {
      this.setState({ bulkEditInputFormData: {} });
    }
  };
  handleResetSingle = () => {
    this.setState({
      isReset: true,
    });
  };
  onResetClick = () => {
    this.setState({
      filterCol: this.state.filterCol.map((el) => ({ ...el, checked: false })),
      dynamicTable: this.state.tblData,
    });
  };
  onDoneClick = () => {
    const { tblData, filterCol, selectedSdmTableValue } = this.state;
    const checkedCols = filterCol
      .filter((el) => el.checked)
      .map((el) => el.fieldName);
    checkedCols.push(this.state.uniqueColumnValue.toLowerCase());
    const filterColumnData = tblData.map((el) => pick(el, checkedCols));
    const arrangedResponse = filterColumnData.map((el: any) => {
      const keys = [
        ...new Set([
          this.state.uniqueColumnObj[selectedSdmTableValue].toLowerCase(),
          ...Object.keys(el),
        ]),
      ];
      const obj: any = {};
      keys.forEach((key) => {
        obj[key] = el[key];
      });
      return obj;
    });
    this.setState({
      dynamicTable: arrangedResponse,
      isOpenFilterByCol: false,
    });
  };

  onToggleCheckbox = (fieldName: string) => {
    this.setState((prev) => {
      return {
        filterCol: prev.filterCol.map((el) => {
          if (el.fieldName === fieldName) {
            return {
              ...el,
              checked: !el.checked,
            };
          }
          return {
            ...el,
          };
        }),
      };
    });
  };

  handleSelectAll = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const { filterCol } = this.state;
    if (_event.target.checked) {
      this.setState({
        filterCol: filterCol?.map((el) => ({ ...el, checked: true })),
      });
    } else {
      this.setState({
        filterCol: filterCol?.map((el) => ({ ...el, checked: false })),
      });
    }
  };

  handleCommentClick = (record: any) => {
    this.setState({ commentForId: record.id });
  };
  handleToolbarIcons = (action: IToolbarMode) => {
    if (action === "edit") {
      this.setState({ isPaginationDisabled: true });
    }

    this.setState({
      mode: action,
      validation: true,
    });
  };
  handleToolbarCancel = (modes?: string) => {
    if (modes === "multipleTableEntries") {
      this.setState(
        {
          mode: "view",
          modes: undefined,
          selectedEntry: "",
          bulkEditInputFormData: {},
          isButtonLoad: false,
          page: 1,
          rows: 5,
          isLoading: false,
        },
        () => {
          this.fetchsortedData();
        }
      );
    } else if (this.state.mode === "edit") {
      this.setState({
        mode: "view",
        modes: undefined,
        selectedEntry: "",
        bulkEditInputFormData: {},
        isButtonLoad: false,
        isdataFiltered: false,
        filteredColumn: { column: "", type: "", value: "" },
        isPaginationDisabled: false,
        isLoading: false,
      });
      this.getTableData(
        this.state.selectedKeyTable
          ? this.state.selectedKeyTable
          : this.state.selectedSdmTableValue
      );
    } else if (this.state.mode === "filter") {
      const collectedData: any = {
        mode: "view",
        modes: undefined,
        selectedEntry: "",
        bulkEditInputFormData: {},
        isButtonLoad: false,
        isPaginationReset: true,
        page: 1,
        isPaginationDisabled: false,
      }
      this.setState(
        collectedData,
        () => {
          this.getTableData(
            this.state.selectedKeyTable
              ? this.state.selectedKeyTable
              : this.state.selectedSdmTableValue
          );
        }
      );
    } else {
      const collectedData: any = {
        mode: "view",
        modes: undefined,
        selectedEntry: "",
        bulkEditInputFormData: {},
        isButtonLoad: false,
        isPaginationDisabled: false,
        isLoading: false,
        rationale: "",
      }
      this.setState(collectedData);
    }
  };

  onActionClick = (action: IconType) => {
    switch (action) {
      case "Edit":
        break;
      case "Delete":
        this.setState({ showDeleteDialoge: true });
        break;
    }
  };

  handleEditClick = () => {
    this.setState({
      mode: "edit",
      validation: true,
      isPaginationDisabled: true,
    });
  };
  handleClone = () => {
    const { selectedSdmTable } = this.state;

    this.setState({ bulkEditInputFormData: selectedSdmTable[0] }, () => {
      this.setState({ mode: "clone", isPaginationDisabled: true });
    });
  };
  onClickEditAction = (
    action: "edit" | "delete" | "clone" | "comment" | "cancel"
  ) => {
    switch (action) {
      case "cancel":
        break;
      case "edit":
        break;
      case "delete":
        this.setState({ showDeleteDialoge: true });
        break;
      case "comment":
        break;
      case "clone":
        break;
      default:
        break;
    }
  };

  handleCountryChange = (selected: SelectModel) => {
    this.setState({ selectedCountry: selected.value });
  };
  handleEntryChange = (selected: SelectModel) => {
    this.setState({
      mode: this.state.mode === "view" ? "edit" : "view",
    });
    if (selected.value === "Insert Single Entry") {
      this.setState({
        modes: "SingleTableEntries",
        selectedEntry: selected.value,
        validation: false,
        isPaginationDisabled: true,
      });
    }
    if (selected.value === "Insert Multiple Entries") {
      this.setState({
        modes: "multipleTableEntries",
        selectedEntry: selected.value,
        validation: false,
        isdataFiltered: false,
        filteredColumn: { column: "", value: "", type: "" },
      });
    }
  };
  handleBulkFieldInsert = () => {
    const fieldObj: any = {};

    Object.keys(this.state.tblData[0]).forEach((key) => {
      fieldObj[key] = null;
    });

    const bulkInsertState = [fieldObj];
    this.setState({
      bulkInsertState,
    });
  };
  handleMenuChange = async (value: string) => {
    this.setState(
      {
        selectedSdmTableValue: value,
        searchText: "",
        isPaginationDisabled: false,
        isPaginationReset: true,
        page: 1,
        rows: 5,
        isdataFiltered: false,
        isLoading: true,
      },
      () => {
        this.getTableData(value);
      }
    );
  };
  handleAddMoreField = () => {
    if (this.state.bulkInsertState.length < 10) {
      const { bulkInsertState: addState } = this.state;
      const fieldObj: any = {};

      Object.keys(this.state.tblData[0]).forEach((key) => {
        fieldObj[key] = null;
      });
      this.setState({
        bulkInsertState: addState.concat({ ...fieldObj }),
      });
    }
  };
  handleKeyTableSelect = (selected: SelectModel, targetId?: string) => {
    if (targetId) {
      this.setState(
        {
          selectedKeyTable: selected.value,
          searchText: "",
          isPaginationDisabled: false,
          isPaginationReset: true,
          page: 1,
          sortedColumn: "",
          sortedDirection: "",
          isLoading: true,
          isdataFiltered: false,
          filteredColumn: { column: "", type: "", value: "" }
        },
        () => {
          this.getTableData(selected.value);
        }
      );
      localStorage.setItem("keyTable", selected.value);
      localStorage.removeItem("sdmTable");
    }
  };
  handleRemoveClick = (index: number) => {
    const { bulkInsertState: addState } = this.state;
    const removeFields = addState.filter(
      (el: any, indx: any) => indx !== index
    );

    this.setState({
      bulkInsertState: removeFields,
    });
  };

  checkDate = (
    target: string,
    date: string,
    tableData: { [key: string]: string }
  ): { validate: boolean; message: string } => {
    let obj: any = {};

    if (target === "Effctv_end_dt".toLowerCase()) {
      const { selectedKeyTable, selectedSdmTableValue } = this.state;
      const keyTable = selectedKeyTable || selectedSdmTableValue;
      obj = SDM_DATE_COLUMNS.find((el) => el.TableName?.includes(keyTable));
    } else {
      obj = SDM_DATE_COLUMNS.find(
        (obj) =>
          target.toLowerCase() === obj.Column1.toLowerCase() ||
          target.toLowerCase() === obj.Column2.toLowerCase()
      );
    }
    if (obj) {
      if (target.toLowerCase() === obj.Column1.toLowerCase()) {
        if (
          !tableData ||
          !tableData[obj.Column2.toLowerCase()] ||
          moment(tableData[obj.Column2.toLowerCase()]).isSameOrAfter(
            moment(date)
          )
        ) {
          return { validate: true, message: "" };
        } else {
          return {
            validate: false,
            message: obj.Column1 + " should not be greater than " + obj.Column2,
          };
        }
      } else if (target.toLowerCase() === obj.Column2.toLowerCase()) {
        if (!tableData[obj.Column1.toLowerCase()]) {
          return { validate: false, message: "Please Enter " + obj.Column1 };
        }
        if (
          !tableData ||
          moment(tableData[obj.Column1.toLowerCase()]).isSameOrBefore(
            moment(date)
          )
        ) {
          return { validate: true, message: "" };
        } else {
          return {
            validate: false,
            message: obj.Column2 + " should not be less than " + obj.Column1,
          };
        }
      } else {
        return {
          validate: false,
          message: "Target does not match with any Column",
        };
      }
    } else {
      return {
        validate: true,
        message: "No matching Column found",
      };
    }
  };
  handleInputChange = (event: any, index: any, targetId?: any, data?: any) => {
    if (
      targetId.toLowerCase().startsWith("dt_") ||
      targetId.toLowerCase().endsWith("_dt") ||
      ["date_val", "date_time_val"].includes(targetId.toLowerCase())
    ) {
      const value = this.checkDate(targetId, event, this.state.tblData[index]);
      if (value.validate) {
        this.setState({
          tblData: this.state.checkedTableData.map((el: any, ind: any) => {
            if (ind === index) {
              return {
                ...el,
                [targetId]: event ? event : null,
                checked: true,
              };
            }
            return el;
          }),
        });
      } else {
        this.setState({
          alertMsg: value.message,
          alertType: "error",
          openAlert: true,
          tblData: this.state.checkedTableData.map((el: any, ind: any) => {
            if (ind === index) {
              return {
                ...el,
                [targetId]: null,
                checked: true,
              };
            }
            return el;
          }),
        });
      }
    } else {
      this.setState({
        tblData: this.state.checkedTableData.map((el: any, ind: any) => {
          if (ind === index) {
            return {
              ...el,
              [targetId]: event.target.value ? event.target.value : null,
              checked: true,
            };
          }
          return el;
        }),
      });
    }
  };

  handleApply = (event: any) => {
    event.preventDefault();
    const { checkedTableData, bulkEditInputFormData, modes, bulkInsertState } =
      this.state;
    if (
      Object.keys(bulkEditInputFormData).length > 0 &&
      modes !== "multipleTableEntries"
    ) {
      const filteredData = Object.fromEntries(
        Object.entries(bulkEditInputFormData).filter(([key]) =>
          key.includes("dt")
        )
      );
      const target = Object.keys(filteredData);
      let sdmObj: any = {};
      if (target) {
        sdmObj = SDM_DATE_COLUMNS.filter((sdmobj) =>
          target.includes(sdmobj.Column1.toLowerCase())
        )[0];
      }
      const obj: any = {};
      Object.keys(bulkEditInputFormData).map((el) => {
        if (`${bulkEditInputFormData[el]}`.length > 0) {
          obj[el] = bulkEditInputFormData[el];
        }
        return el;
      });
      const modifiedData = checkedTableData.map((el: any, ind: any) => {
        if (el.checked === true) {
          return {
            ...el,
            ...obj,
          };
        }
        return el;
      });
      if (!_.isEmpty(sdmObj)) {
        const checkDate = modifiedData.map((el: any) => {
          return moment(el[sdmObj.Column1.toLowerCase()]).isSameOrBefore(
            moment(el[sdmObj.Column2.toLowerCase()])
          )
            ? el
            : { ...el, [sdmObj.Column2.toLowerCase()]: null };
        });
        this.setState({
          tblData: checkDate,
        });
      } else {
        this.setState({
          tblData: modifiedData,
        });
      }
    }
    if (modes === "multipleTableEntries") {
      const obj: any = {};
      Object.keys(bulkEditInputFormData).forEach((el) => {
        if (`${bulkEditInputFormData[el]}`.length > 0) {
          obj[el] = bulkEditInputFormData[el];
        }
        return el;
      });
      this.setState({
        bulkInsertState: bulkInsertState.map((el: any, ind: any) => {
          return {
            ...el,
            ...obj,
          };
        }),
      });
    }
  };
  handleBulkInputChange = (
    event: any,
    index: any,
    targetId?: any,
    data?: any
  ) => {
    if (
      targetId.toLowerCase().startsWith("dt_") ||
      targetId.toLowerCase().endsWith("_dt") ||
      ["date_val", "date_time_val"].includes(targetId.toLowerCase())
    ) {
      const value = this.checkDate(
        targetId,
        event,
        this.state.bulkEditInputFormData[index] ||
        this.state.bulkEditInputFormData
      );
      if (value.validate) {
        this.setState({
          bulkEditInputFormData: {
            ...this.state.bulkEditInputFormData,
            [targetId]: event ? event : null,
          },
        });
      } else {
        this.setState({
          alertMsg: value.message,
          alertType: "error",
          openAlert: true,
          bulkEditInputFormData: {
            ...this.state.bulkEditInputFormData,
            [targetId]: null,
          },
        });
      }
    } else {
      this.setState({
        bulkEditInputFormData: {
          ...this.state.bulkEditInputFormData,
          [targetId]: event.target.value ? event.target.value : null,
        },
      });
    }
  };
  handleBulkInsertInputChange = (
    event: any,
    index: any,
    targetId?: any,
    data?: any
  ) => {
    if (
      targetId.toLowerCase().startsWith("dt_") ||
      targetId.toLowerCase().endsWith("_dt") ||
      ["date_val", "date_time_val"].includes(targetId.toLowerCase())
    ) {
      const value = this.checkDate(
        targetId,
        event,
        this.state.bulkInsertState[index] || this.state.bulkInsertState
      );
      if (value.validate) {
        this.setState({
          bulkInsertState: this.state.bulkInsertState.map(
            (el: any, ind: any) => {
              if (index === ind) {
                return {
                  ...el,
                  [targetId]: event ? event : null,
                };
              }
              return el;
            }
          ),
        });
      } else {
        this.setState({
          alertMsg: value.message,
          alertType: "error",
          openAlert: true,
          bulkInsertState: this.state.bulkInsertState.map(
            (el: any, ind: any) => {
              if (index === ind) {
                return {
                  ...el,
                  [targetId]: "",
                };
              }
              return el;
            }
          ),
        });
      }
    } else {
      this.setState({
        bulkInsertState: this.state.bulkInsertState.map((el: any, ind: any) => {
          if (index === ind) {
            return {
              ...el,
              /* [targetId]:  event.target.value ? event.target.value : null, */
              [targetId]: event.target.value ? event.target.value : null,
            };
          }
          return el;
        }),
      });
    }
  };

  getTableData = async (
    value: string,
    sortedColumn?: string | null,
    sortedDirection?: string | null
  ) => {
    try {
      this.setState({ isTableDataLoading: true });
      const obj: GetSDMTableRequestModel = {
        sdm_tablename: value.toLowerCase(),
        var_whereclause: null,
        var_order: sortedColumn ? `${sortedColumn} ${sortedDirection}` : null,
        var_page: this.state.page,
        var_recordsperpage: this.state.rows,
      };

      const response = await SDMTableServices.getSDMTableData(obj);
      if (response.result.length > 0 && this.state.uniqueColumnObj[value]) {
        this.handleToolbarCancel();
        const columnArr = Object.keys(response.result[0]).map((key) => {
          return {
            fieldName: key,
            checked: false,
          };
        });
        const arrangedResponse = response.result.map((el: any) => {
          const keys = [
            ...new Set([
              this.state.uniqueColumnObj[value].toLowerCase(),
              ...Object.keys(el),
            ]),
          ];
          const newKeys = keys.filter(
            (elem) => !["primary_col_name", "review_status"].includes(elem)
          );

          const newKeysCol =
            this.state.selectedSdmTableValue === "RPT_OVERRIDES_ARCHIVE"
              ? newKeys.filter(
                (elem) => !["notification_id", "review_status"].includes(elem)
              )
              : newKeys;

          const obj: any = {};
          newKeysCol.forEach((key) => {
            obj[key] = el[key];
          });
          return obj;
        });
        this.setState(
          {
            tblData: arrangedResponse,
            headcellData: Object.keys(arrangedResponse[0]),
            singleTableData: arrangedResponse,
            dynamicTable: arrangedResponse,
            filterCol: columnArr.filter(
              (el) =>
                ![
                  "primary_col_name",
                  "review_status",
                  this.state.uniqueColumnObj[value].toLowerCase(),
                ].includes(el.fieldName)
            ),
            totalRecordCount: Number(response.total_count),
            uniqueColumnValue: this.state.uniqueColumnObj[value],
            bulkEditInputFormData: {},
          },
          () => {
            this.getColumnDetails({ tableName: value.toLowerCase() });
            this.handleBulkFieldInsert();
            this.setState({
              isLoading: false,
              isPaginationReset: false,
              isEmptyReset: false,
              isTableDataLoading: false,
            });
          }
        );
      } else if (
        response.result.length === 0 &&
        this.state.uniqueColumnObj[value] &&
        this.state.page > 1
      ) {
        this.handleToolbarCancel();
        const obj: GetSDMTableRequestModel = {
          sdm_tablename: value.toLowerCase(),
          var_whereclause: null,
          var_order: sortedColumn ? `${sortedColumn} ${sortedDirection}` : null,
          var_page: 1,
          var_recordsperpage: this.state.rows,
        };
        const response = await SDMTableServices.getSDMTableData(obj);
        const columnArr = Object.keys(response.result[0]).map((key) => {
          return {
            fieldName: key,
            checked: false,
          };
        });
        const arrangedResponse = response.result.map((el: any) => {
          const keys = [
            ...new Set([
              this.state.uniqueColumnObj[value].toLowerCase(),
              ...Object.keys(el),
            ]),
          ];
          const newKeys = keys.filter(
            (elem) => !["primary_col_name", "review_status"].includes(elem)
          );
          const obj: any = {};
          newKeys.forEach((key) => {
            obj[key] = el[key];
          });
          return obj;
        });

        this.setState(
          {
            tblData: arrangedResponse,
            singleTableData: arrangedResponse,
            dynamicTable: arrangedResponse,
            filterCol: columnArr.filter(
              (el) =>
                ![
                  "primary_col_name",
                  "review_status",
                  this.state.uniqueColumnObj[value].toLowerCase(),
                ].includes(el.fieldName)
            ),
            totalRecordCount: Number(response.total_count),
            uniqueColumnValue: this.state.uniqueColumnObj[value],
            bulkEditInputFormData: {},
          },
          () => {
            this.getColumnDetails({ tableName: value.toLowerCase() });
            this.handleBulkFieldInsert();
            this.setState({
              isLoading: false,
              isEmptyReset: true,
              page: 1,
              isTableDataLoading: false,
            });
          }
        );
      } else if (
        response.result.length === 0 &&
        this.state.uniqueColumnObj[value]
      ) {
        this.setState({
          isLoading: false,
          isTableDataLoading: false,
          tblData: [],
          totalRecordCount: 0,
          filterCol: [],
          uniqueColumnValue: this.state.uniqueColumnObj[value],
        });
        this.getColumnDetails({ tableName: value.toLowerCase() });
      } else {
        this.setState({
          isLoading: false,
          isTableDataLoading: false,
          tblData: [],
          singleTableData: [],
          dynamicTable: [],
          filterCol: [],
          totalRecordCount: 0,
          uniqueColumnValue: "",
          bulkEditInputFormData: {},
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  getSearchFilterTableData = (response: any) => {
    const columnArr = Object.keys(response.result[0]).map((key) => {
      return {
        fieldName: key,
        checked: false,
      };
    });
    const arrangedResponse = response.result.map((el: any) => {
      const keys = [
        ...new Set([
          this.state.uniqueColumnObj[
            this.state.selectedKeyTable
              ? this.state.selectedKeyTable
              : this.state.selectedSdmTableValue
          ].toLowerCase(),
          ...Object.keys(el),
        ]),
      ];
      const newKeys = keys.filter(
        (elem) => !["primary_col_name", "review_status"].includes(elem)
      );
      const obj: any = {};
      newKeys.forEach((key) => {
        obj[key] = el[key];
      });
      return obj;
    });

    this.setState({
      tblData: arrangedResponse,
      singleTableData: arrangedResponse,
      dynamicTable: arrangedResponse,
      filterCol: columnArr.filter(
        (el) =>
          ![
            "primary_col_name",
            "review_status",
            this.state.uniqueColumnObj[
              this.state.selectedKeyTable
                ? this.state.selectedKeyTable
                : this.state.selectedSdmTableValue
            ].toLowerCase(),
          ].includes(el.fieldName)
      ),
      totalRecordCount: Number(response.total_count),
      uniqueColumnValue:
        this.state.uniqueColumnObj[
        this.state.selectedKeyTable
          ? this.state.selectedKeyTable
          : this.state.selectedSdmTableValue
        ],
      bulkEditInputFormData: {},
      isLoading: false,
      isdataFiltered: true,
      isPaginationReset: false,
      isEmptyReset: false,
      isTableDataLoading: false,
    });
  };
  getColumnDetails = async (tableName: GetColumnDetailsRequestModel) => {
    const response = await SDMTableServices.getColumnDetails(tableName);
    const fieldName = response.data.fieldsData.map((key) => key.fieldName);
    fieldName.filter(
      (val) => val.toLowerCase() === this.state.uniqueColumnValue.toLowerCase()
    );
    fieldName.unshift(this.state.uniqueColumnValue.toLowerCase());
    let obj: any = {};
    fieldName.map((value) => {
      return (obj[value] = null);
    });

    if (this.state.tblData.length === 0 && this.state.page === 1) {
      this.setState({ tblData: [obj] }, () => {
        this.setState({ isLoading: false });
        this.handleBulkFieldInsert();
      });
    }
    this.setState({
      columnDetails: response.data.fieldsData.filter(
        (el) =>
          ![
            "last_updt_dt",
            "last_updt_usr",
            "lst_updt_dt",
            "lst_updt_usr",
            "ovr_last_updt_dt",
            "ovr_last_updt_usr",
            "review_status",
            this.state.uniqueColumnValue.toLowerCase(),
          ].includes(el.fieldName)
      ),
      AllColumnDetails: response.data.fieldsData,
    });
  };
  onRationaleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ rationale: event.target.value });
  };

  onVerifyFields = () => {
    const {
      columnDetails,
      bulkInsertState,
      bulkEditInputFormData,
      modes,
      mode,
      selectedSdmTable,
    } = this.state;
    const requiredCol = columnDetails.filter((el: any) => {
      return el.isRequired.toLowerCase() === "no";
    });
    const requireColArr = requiredCol.map((ele: any) =>
      ele.fieldName.toLowerCase()
    );

    const emptyCheck = (inputdata: any) => {
      const emptyKeyArr: any = [];
      requireColArr.forEach((ele: any) => {
        if (
          inputdata.hasOwnProperty(ele) &&
          (inputdata[ele] === null ||
            inputdata[ele] === undefined ||
            inputdata[ele] === "")
        ) {
          emptyKeyArr.push(ele);
        }
      });

      return emptyKeyArr.length === 0;
    };

    if (modes === "SingleTableEntries") {
      if (Object.keys(bulkEditInputFormData).length > 0) {
        let emptyKeyArr: any = [];

        requireColArr.forEach((ele: any) => {
          if (
            bulkEditInputFormData[ele] === null ||
            bulkEditInputFormData[ele] === undefined ||
            bulkEditInputFormData[ele] === ""
          ) {
            emptyKeyArr.push(ele);
          }
        });
        return emptyKeyArr.length === 0;
      } else {
        return false;
      }
    } else if (mode === "edit" && selectedSdmTable.length === 1) {
      if (emptyCheck(selectedSdmTable[0])) {
        return true;
      } else {
        return false;
      }
    } else if (mode === "clone") {
      if (emptyCheck(bulkEditInputFormData)) {
        return true;
      } else {
        return false;
      }
    } else if (mode === "comment") {
      if (this.state.rationale.length > 0) {
        return true;
      } else {
        return false;
      }
    } else if (selectedSdmTable.length > 1) {
      const isRequireArr = selectedSdmTable.map((ele: any) => {
        if (emptyCheck(ele)) {
          return true;
        } else {
          return false;
        }
      });
      return isRequireArr.includes(false) ? false : true;
    } else {
      if (modes === "multipleTableEntries") {
        const isRequireArr = bulkInsertState.map((ele: any) => {
          if (emptyCheck(ele)) {
            return true;
          } else {
            return false;
          }
        });
        return isRequireArr.includes(false) ? false : true;
      }
    }
  };
  afterSaveFetchData = () => {
    const { selectedKeyTable, selectedSdmTableValue } = this.state;
    this.setState({ sortedColumn: "", sortedDirection: "" }, () => {
      this.getTableData(
        selectedKeyTable ? selectedKeyTable : selectedSdmTableValue
      );
    });
  };

  handleSaveRecord = async (mode: string) => {
    const {
      bulkInsertState,
      selectedSdmTableValue,
      bulkEditInputFormData,
      AllColumnDetails,
      userId,
      selectedSdmTable,
      singleTableData,
      uniqueColumnValue,
      rationale,
      selectedKeyTable,
    } = this.state;

    if (this.onVerifyFields() === true) {
      this.setState({ validation: false }, async () => {
        try {
          if (mode === "comment") {
            this.setState({ isButtonLoad: true });
            const editArr = [];
            const selected = selectedSdmTable.find(
              (el: any) => el.checked === true
            );
            if (
              selected.hasOwnProperty("last_updt_dt") &&
              selected.hasOwnProperty("last_updt_usr")
            ) {
              const singleEditObj = {
                [uniqueColumnValue.toLowerCase()]:
                  selected[uniqueColumnValue.toLowerCase()],
                rationale: rationale,
              };

              const obj = {
                last_updt_dt: Utils.getCurrentUTCDateTimeMonth(),
                last_updt_usr: userId,
              };
              editArr.push({ ...singleEditObj, ...obj });
            } else if (
              selected.hasOwnProperty("ovr_last_updt_dt") &&
              selected.hasOwnProperty("ovr_last_updt_usr")
            ) {
              const singleEditObj = {
                [uniqueColumnValue.toLowerCase()]:
                  selected[uniqueColumnValue.toLowerCase()],
                rationale: rationale,
              };

              const obj = {
                ovr_last_updt_dt: Utils.getCurrentUTCDateTimeMonth(),
                ovr_last_updt_usr: userId,
              };
              editArr.push({ ...singleEditObj, ...obj });
            } else {
              const singleEditObj = {
                [uniqueColumnValue.toLowerCase()]:
                  selected[uniqueColumnValue.toLowerCase()],
                rationale: rationale,
              };

              const obj = {
                lst_updt_dt: Utils.getCurrentUTCDateTimeMonth(),
                lst_updt_usr: userId,
              };
              editArr.push({ ...singleEditObj, ...obj });
            }
            const requestObj: SDMRecordPayloadModel = {
              sdm_tablename: selectedKeyTable
                ? selectedKeyTable.toLowerCase()
                : selectedSdmTableValue.toLowerCase(),
              sdm_payload: editArr,
            };
            const response = await SDMTableServices.updateRecords(requestObj);

            if (response.result === "Success") {
              this.setState(
                {
                  alertMsg: "Rationale is added successfully.",
                  alertType: "success",
                  openAlert: true,
                  isButtonLoad: false,
                  rationale: "",
                  mode: "filter",
                },
                () => {
                  this.handleToolbarCancel();
                  this.afterSaveFetchData();
                }
              );
            } else {
              this.setState({
                alertMsg: _.capitalize(
                  response.result.split("CONTEXT")[0].toLowerCase()
                ),
                alertType: "error",
                openAlert: true,
                isButtonLoad: false,
                rationale: "",
              });
            }
          }
          if (mode === "SingleTableEntries") {
            this.setState({ isButtonLoad: true });
            const newObj: any = {};
            AllColumnDetails.map((el) => {
              if (["lst_updt_dt", "lst_updt_usr"].includes(el.fieldName)) {
                newObj["lst_updt_dt"] = Utils.getCurrentUTCDateTimeMonth();
                newObj["lst_updt_usr"] = userId;
              } else if (
                ["ovr_last_updt_dt", "ovr_last_updt_usr"].includes(el.fieldName)
              ) {
                newObj["ovr_last_updt_dt"] = Utils.getCurrentUTCDateTimeMonth();
                newObj["ovr_last_updt_usr"] = userId;
              } else {
                newObj["last_updt_dt"] = Utils.getCurrentUTCDateTimeMonth();
                newObj["last_updt_usr"] = userId;
              }
              return el;
            });

            const requestObj: SDMRecordPayloadModel = {
              sdm_tablename: selectedKeyTable
                ? selectedKeyTable.toLowerCase()
                : selectedSdmTableValue.toLowerCase(),
              sdm_payload: [{ ...bulkEditInputFormData, ...newObj }],
            };
            const response = await SDMTableServices.createRecords(requestObj);

            if (response.result === "Success") {
              this.getTableData(
                selectedKeyTable ? selectedKeyTable : selectedSdmTableValue
              );
              this.setState({
                alertMsg: "Record is created successfully.",
                alertType: "success",
                openAlert: true,
                isButtonLoad: false,
              });
            } else {
              this.setState({
                alertMsg: _.capitalize(
                  response.result.split("CONTEXT")[0].toLowerCase()
                ),
                alertType: "error",
                openAlert: true,
                isButtonLoad: false,
              });
            }
          }
          if (mode === "clone") {
            if (this.state.isdataFiltered) {
              this.setState({
                isdataFiltered: false,
                filteredColumn: { column: "", value: "", type: "" },
                isPaginationReset: true,
              });
            }
            this.setState({ isButtonLoad: true });
            const newObj: any = {};
            AllColumnDetails.map((el) => {
              if (["lst_updt_dt", "lst_updt_usr"].includes(el.fieldName)) {
                newObj["lst_updt_dt"] = Utils.getCurrentUTCDateTimeMonth();
                newObj["lst_updt_usr"] = userId;
              } else if (
                ["ovr_last_updt_dt", "ovr_last_updt_usr"].includes(el.fieldName)
              ) {
                newObj["ovr_last_updt_dt"] = Utils.getCurrentUTCDateTimeMonth();
                newObj["ovr_last_updt_usr"] = userId;
              } else {
                newObj["last_updt_dt"] = Utils.getCurrentUTCDateTimeMonth();
                newObj["last_updt_usr"] = userId;
              }
              return el;
            });
            const cloneObj = bulkEditInputFormData;
            delete cloneObj[uniqueColumnValue.toLowerCase()];
            delete cloneObj["checked"];
            const requestObj: SDMRecordPayloadModel = {
              sdm_tablename: selectedKeyTable
                ? selectedKeyTable.toLowerCase()
                : selectedSdmTableValue.toLowerCase(),
              sdm_payload: [{ ...cloneObj, ...newObj }],
            };
            const response = await SDMTableServices.createRecords(requestObj);

            if (response.result === "Success") {
              this.afterSaveFetchData();
              this.setState({
                alertMsg: "Record is created successfully.",
                alertType: "success",
                openAlert: true,
                isButtonLoad: false,
              });
            } else {
              this.setState({
                alertMsg: _.capitalize(
                  response.result.split("CONTEXT")[0].toLowerCase()
                ),
                alertType: "error",
                openAlert: true,
                isButtonLoad: false,
              });
            }
          }
          if (mode === "edit") {
            if (this.state.isdataFiltered) {
              this.setState({
                isdataFiltered: false,
                filteredColumn: { column: "", value: "", type: "" },
                isPaginationReset: true,
              });
            }
            this.setState({ isButtonLoad: true });
            const differences: any[] = [];
            const mergedObj: any[] = [];

            singleTableData.forEach((obj1: any) => {
              const obj2 = selectedSdmTable.find(
                (obj: any) =>
                  obj[uniqueColumnValue.toLowerCase()] ===
                  obj1[uniqueColumnValue.toLowerCase()]
              );

              if (obj2) {
                const differenceObj: any = {
                  [uniqueColumnValue.toLowerCase()]:
                    obj2[uniqueColumnValue.toLowerCase()],
                };

                for (let key in obj1) {
                  if (obj1[key] !== obj2[key]) {
                    differenceObj[key] = obj2[key];
                  }
                }

                differences.push(differenceObj);
                const existingObj = mergedObj.find(
                  (el: any) =>
                    el[uniqueColumnValue.toLowerCase()] ===
                    obj2[uniqueColumnValue.toLowerCase()]
                );

                if (existingObj) {
                  Object.assign(existingObj, differenceObj);
                } else {
                  if (
                    Object.keys(selectedSdmTable[0]).find((el: any) =>
                      ["lst_updt_dt", "lst_updt_usr"].includes(el)
                    )
                  ) {
                    const obj = {
                      lst_updt_dt: Utils.getCurrentUTCDateTimeMonth(),
                      lst_updt_usr: userId,
                    };
                    mergedObj.push({ ...differenceObj, ...obj });
                  } else if (
                    Object.keys(selectedSdmTable[0]).find((el: any) =>
                      ["ovr_last_updt_dt", "ovr_last_updt_usr"].includes(el)
                    )
                  ) {
                    const obj = {
                      ovr_last_updt_dt: Utils.getCurrentUTCDateTimeMonth(),
                      ovr_last_updt_usr: userId,
                    };
                    mergedObj.push({ ...differenceObj, ...obj });
                  } else {
                    const obj = {
                      last_updt_dt: Utils.getCurrentUTCDateTimeMonth(),
                      last_updt_usr: userId,
                    };
                    mergedObj.push({ ...differenceObj, ...obj });
                  }
                }
              }
            });
            let payloadObj = mergedObj.map((obj) => {
              if ("notification_id" in obj) {
                return { ...obj, review_status: null };
              } else {
                return obj;
              }
            });
            const requestObj: SDMRecordPayloadModel = {
              sdm_tablename: selectedKeyTable
                ? selectedKeyTable.toLowerCase()
                : selectedSdmTableValue.toLowerCase(),
              sdm_payload: payloadObj,
            };
            const response = await SDMTableServices.updateRecords(requestObj);

            if (response.result === "Success") {
              this.setState(
                {
                  alertMsg: "Record is updated successfully.",
                  alertType: "success",
                  openAlert: true,
                  isButtonLoad: false,
                },
                () => {
                  this.handleToolbarCancel();
                  this.afterSaveFetchData();
                }
              );
            } else {
              this.setState({
                alertMsg: _.capitalize(
                  response.result.split("CONTEXT")[0].toLowerCase()
                ),
                alertType: "error",
                openAlert: true,
                isButtonLoad: false,
              });
            }
          }
          if (mode === "bulkEdit") {
            if (this.state.isdataFiltered) {
              this.setState({
                isdataFiltered: false,
                filteredColumn: { column: "", value: "", type: "" },
                isPaginationReset: true,
              });
            }
            this.setState({ isButtonLoad: true });
            if (selectedSdmTable.length === singleTableData.length) {
              const changedProperties = [];

              for (let i = 0; i < singleTableData.length; i++) {
                const originalObj = singleTableData[i];
                const updatedObj = selectedSdmTable[i];

                const changes: any = {};

                for (const key in originalObj) {
                  if (originalObj[key] !== updatedObj[key]) {
                    changes[key] = updatedObj[key];
                  }
                }

                if (Object.keys(changes).length > 0) {
                  const changedObject = {
                    [uniqueColumnValue.toLowerCase()]:
                      updatedObj[uniqueColumnValue.toLowerCase()],
                  };

                  for (const key in changes) {
                    changedObject[key] = changes[key];
                  }
                  if (
                    Object.keys(selectedSdmTable[0]).find((el: any) =>
                      ["lst_updt_dt", "lst_updt_usr"].includes(el)
                    )
                  ) {
                    const obj = {
                      lst_updt_dt: Utils.getCurrentUTCDateTimeMonth(),
                      lst_updt_usr: userId,
                    };
                    changedProperties.push({ ...changedObject, ...obj });
                  } else if (
                    Object.keys(selectedSdmTable[0]).find((el: any) =>
                      ["ovr_last_updt_dt", "ovr_last_updt_usr"].includes(el)
                    )
                  ) {
                    const obj = {
                      ovr_last_updt_dt: Utils.getCurrentUTCDateTimeMonth(),
                      ovr_last_updt_usr: userId,
                    };
                    changedProperties.push({ ...changedObject, ...obj });
                  } else {
                    const obj = {
                      last_updt_dt: Utils.getCurrentUTCDateTimeMonth(),
                      last_updt_usr: userId,
                    };
                    changedProperties.push({ ...changedObject, ...obj });
                  }
                }
              }
              let payloadObj = changedProperties.map((obj) => {
                if ("notification_id" in obj) {
                  return { ...obj, review_status: null };
                } else {
                  return obj;
                }
              });

              const requestObj: SDMRecordPayloadModel = {
                sdm_tablename: selectedKeyTable
                  ? selectedKeyTable.toLowerCase()
                  : selectedSdmTableValue.toLowerCase(),
                sdm_payload: payloadObj,
              };

              const response = await SDMTableServices.updateRecords(requestObj);

              if (response.result === "Success") {
                this.afterSaveFetchData();
                this.setState({
                  alertMsg: "Records are updated successfully.",
                  alertType: "success",
                  openAlert: true,
                  isButtonLoad: false,
                });
              } else {
                this.setState({
                  alertMsg: _.capitalize(
                    response.result.split("CONTEXT")[0].toLowerCase()
                  ),
                  alertType: "error",
                  openAlert: true,
                  isButtonLoad: false,
                });
              }
            } else {
              const differences: any[] = [];
              const mergedObj: any[] = [];
              singleTableData.forEach((obj1: any) => {
                const obj2 = selectedSdmTable.find(
                  (obj2: any) =>
                    obj2[uniqueColumnValue] === obj1[uniqueColumnValue]
                );

                if (obj2) {
                  const differenceObj = {
                    [uniqueColumnValue]: obj2[uniqueColumnValue],
                  };

                  for (const key in obj1) {
                    if (obj1[key] !== obj2[key]) {
                      differenceObj[key] = obj2[key];
                    }
                  }

                  if (Object.keys(differenceObj).length > 1) {
                    differences.push(differenceObj);
                    const existingObj = mergedObj.find(
                      (el: any) =>
                        el[uniqueColumnValue.toLowerCase()] ===
                        obj2[uniqueColumnValue.toLowerCase()]
                    );

                    if (existingObj) {
                      Object.assign(existingObj, differenceObj);
                    } else {
                      if (
                        Object.keys(selectedSdmTable[0]).find((el: any) =>
                          ["lst_updt_dt", "lst_updt_usr"].includes(el)
                        )
                      ) {
                        const obj = {
                          lst_updt_dt: Utils.getCurrentUTCDateTimeMonth(),
                          lst_updt_usr: userId,
                        };
                        mergedObj.push({ ...differenceObj, ...obj });
                      } else if (
                        Object.keys(selectedSdmTable[0]).find((el: any) =>
                          ["ovr_last_updt_dt", "ovr_last_updt_usr"].includes(el)
                        )
                      ) {
                        const obj = {
                          ovr_last_updt_dt: Utils.getCurrentUTCDateTimeMonth(),
                          ovr_last_updt_usr: userId,
                        };
                        mergedObj.push({ ...differenceObj, ...obj });
                      } else {
                        const obj = {
                          last_updt_dt: Utils.getCurrentUTCDateTimeMonth(),
                          last_updt_usr: userId,
                        };
                        mergedObj.push({ ...differenceObj, ...obj });
                      }
                    }
                  }
                }
              });
              let payloadObj = mergedObj.map((obj) => {
                if ("notification_id" in obj) {
                  return { ...obj, review_status: null };
                } else {
                  return obj;
                }
              });
              const requestObj: SDMRecordPayloadModel = {
                sdm_tablename: selectedKeyTable
                  ? selectedKeyTable.toLowerCase()
                  : selectedSdmTableValue.toLowerCase(),
                sdm_payload: payloadObj,
              };

              const response = await SDMTableServices.updateRecords(requestObj);

              if (response.result === "Success") {
                this.afterSaveFetchData();
                this.setState({
                  alertMsg:
                    selectedSdmTable.length > 1
                      ? "Records are updated successfully."
                      : "Record is updated successfully.",
                  alertType: "success",
                  openAlert: true,
                  isButtonLoad: false,
                });
              } else {
                this.setState({
                  alertMsg: _.capitalize(
                    response.result.split("CONTEXT")[0].toLowerCase()
                  ),
                  alertType: "error",
                  openAlert: true,
                  isButtonLoad: false,
                });
              }
            }
          }
          if (mode === "multipleTableEntries") {
            this.setState({ isButtonLoad: true });

            const payloadArr = bulkInsertState.map((el: any) => {
              if (
                Object.keys(bulkInsertState[0]).find((el: any) =>
                  ["lst_updt_dt", "lst_updt_usr"].includes(el)
                )
              ) {
                return {
                  ...el,
                  lst_updt_dt: Utils.getCurrentUTCDateTimeMonth(),
                  lst_updt_usr: userId,
                };
              } else if (
                Object.keys(bulkInsertState[0]).find((el: any) =>
                  ["ovr_last_updt_dt", "ovr_last_updt_usr"].includes(el)
                )
              ) {
                return {
                  ...el,
                  ovr_last_updt_dt: Utils.getCurrentUTCDateTimeMonth(),
                  ovr_last_updt_usr: userId,
                };
              } else {
                return {
                  ...el,
                  last_updt_dt: Utils.getCurrentUTCDateTimeMonth(),
                  last_updt_usr: userId,
                };
              }
            });
            const requestObj: SDMRecordPayloadModel = {
              sdm_tablename: selectedKeyTable
                ? selectedKeyTable.toLowerCase()
                : selectedSdmTableValue.toLowerCase(),
              sdm_payload: payloadArr,
            };
            const response = await SDMTableServices.createRecords(requestObj);

            if (response.result === "Success") {
              this.afterSaveFetchData();
              this.setState({
                alertMsg: "Records are created successfully.",
                alertType: "success",
                openAlert: true,
                isButtonLoad: false,
              });
            } else {
              this.setState({
                alertMsg: _.capitalize(
                  response.result.split("CONTEXT")[0].toLowerCase()
                ),
                alertType: "error",
                openAlert: true,
                isButtonLoad: false,
              });
            }
          }
        } catch (error) {
          this.setState({
            alertMsg: "Something went wrong.",
            alertType: "error",
            openAlert: true,
            isButtonLoad: false,
          });
        }
      });
    } else {
      this.setState({
        alertMsg: "Please fill the required fields",
        alertType: "warning",
        openAlert: true,
        isButtonLoad: false,
        validation: true,
      });
    }
  };
  handleDelete = async () => {
    const {
      selectedSdmTableValue,
      selectedSdmTable,
      sortedColumn,
      sortedDirection,
      uniqueColumnValue,
      selectedKeyTable,
    } = this.state;
    this.setState({ isButtonLoad: true });
    try {
      const filterArr = selectedSdmTable.map((el: any) => ({
        [uniqueColumnValue.toLowerCase()]: el[uniqueColumnValue.toLowerCase()],
      }));

      const requestObj: SDMRecordPayloadModel = {
        sdm_tablename: selectedKeyTable
          ? selectedKeyTable.toLowerCase()
          : selectedSdmTableValue.toLowerCase(),
        sdm_payload: filterArr,
      };
      const response = await SDMTableServices.deleteRecords(requestObj);

      if (response.result === "Success") {
        sortedColumn
          ? this.getTableData(
            selectedKeyTable ? selectedKeyTable : selectedSdmTableValue,
            sortedColumn,
            sortedDirection
          )
          : this.getTableData(
            selectedKeyTable ? selectedKeyTable : selectedSdmTableValue
          );
        this.setState(
          {
            alertMsg:
              selectedSdmTable.length > 1
                ? "Records are deleted successfully."
                : "Record is deleted successfully.",
            alertType: "success",
            openAlert: true,
            showDeleteDialoge: false,
            isButtonLoad: false,
          },
          () => { }
        );
      } else {
        this.setState({
          alertMsg: _.capitalize(
            response.result.split("CONTEXT")[0].toLowerCase()
          ),
          alertType: "error",
          openAlert: true,
          showDeleteDialoge: false,
          isButtonLoad: false,
        });
      }
    } catch (error) {
      this.setState({
        alertMsg: "Something went wrong",
        alertType: "error",
        openAlert: true,
        showDeleteDialoge: false,
        isButtonLoad: false,
      });
    }
  };
  onClickLink = (_fieldClicked: string, record: any) => { };

  handleTemplateDownload = async (event: any) => {
    if (
      this.state.selectedSdmTableValue === "RPT_STATE_LIC_EXCPTN" ||
      this.state.selectedSdmTableValue === "RPT_STATE_MA_RCP" ||
      this.state.selectedSdmTableValue === "RPT_STATE_VT_HCP_RCP"
    ) {
      ExcelService.CreateTemplate(
        this.state.selectedSdmTableValue,
        sdmTemplate[this.state.selectedSdmTableValue]
      );
    } else if (
      this.state.selectedSdmTableValue === "RPT_OVERRIDES" ||
      this.state.selectedKeyTable === "RPT_OVERRIDES"
    ) {
      const columnData = sdmTemplate["RPT_OVERRIDES"]
        .map((val) => val.toLowerCase())
        .filter(
          (el) =>
            ![
              "ovr_last_updt_dt",
              "ovr_last_updt_usr",
              "notification_id",
            ].includes(el)
        );
      ExcelService.CreateTemplate("RPT_OVERRIDES", columnData);
    } else {
      const filteredColumn = Object.keys(this.state.tblData[0]).filter(
        (el) =>
          ![
            "primary_col_name",
            "last_updt_dt",
            "last_updt_usr",
            "lst_updt_dt",
            "lst_updt_usr",
            "ovr_last_updt_dt",
            "ovr_last_updt_usr",
            "review_status",
            "notification_id",
            this.state.uniqueColumnValue.toLowerCase(),
          ].includes(el)
      );

      ExcelService.CreateTemplate(
        this.state.selectedKeyTable
          ? this.state.selectedKeyTable
          : this.state.selectedSdmTableValue,
        filteredColumn
      );
    }
  };

  onClickButton = (action: "upload") => {
    switch (action) {
      case "upload":
        this.props.history.push({
          pathname: "/fileupload",
        });
        break;
    }
  };

  fetchFilterData = async (obj: any, searchedDetails: any) => {
    try {
      if (this.state.filteredColumn.value !== searchedDetails.searchValue) {
        this.setState({ isPaginationReset: true });
      }
      this.setState({ isTableDataLoading: true });
      const { fieldName, type, searchValue } = searchedDetails;
      const filterResponseData = await SDMTableServices.getSDMTableData(obj);

      if (filterResponseData?.result.length > 0) {
        if (filterResponseData) {
          this.setState(
            {
              isdataFiltered: true,
              filteredColumn: {
                column: fieldName,
                value: searchValue,
                type: type,
              },
              isPaginationDisabled: false,
            },
            () => this.getSearchFilterTableData(filterResponseData)
          );
        }
      } else {
        this.setState({
          tblData: filterResponseData?.result,
          isdataFiltered: true,
          isTableDataLoading: false,
          filteredColumn: { column: fieldName, value: searchValue, type: type },
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        isTableDataLoading: false,
        alertMsg: "Something went wrong.",
        alertType: "error",
        openAlert: true,
      });
    }
  };
  handleRemoveFilter = () => {
    this.setState(
      {
        isdataFiltered: false,
        filteredColumn: { column: "", value: "", type: "" },
        isPaginationReset: true,
        page: 1,
      }, () => {
        this.handleToolbarCancel();
      }
    );
  };
  fetchsortedData = () => {
    const { sortedColumn, sortedDirection } = this.state;

    sortedColumn
      ? this.getTableData(
        this.state.selectedKeyTable
          ? this.state.selectedKeyTable
          : this.state.selectedSdmTableValue,
        sortedColumn,
        sortedDirection
      )
      : this.getTableData(
        this.state.selectedKeyTable
          ? this.state.selectedKeyTable
          : this.state.selectedSdmTableValue
      );
  };
  handleSorting = async (key: string, direction: string) => {
    const { selectedKeyTable, selectedSdmTableValue } = this.state;
    const order = direction === "ascending" ? "asc" : "desc";
    this.setState(
      {
        sortedColumn: key,
        sortedDirection: order,
        page: 1,
        rows: 5,
        isPaginationReset: true,
      },
      () => {
        this.getTableData(
          selectedKeyTable ? selectedKeyTable : selectedSdmTableValue,
          key,
          order
        );
      }
    );
  };
  render() {
    const {
      filterCol,
      tblData,
      columnDetails,
      selectedSdmTableValue,
      totalRecordCount,
      isLoading,
      isSDMListLoading,
      uniqueColumnValue,
      selectedEntry,
      mode,
      alertMsg,
      alertType,
      openAlert,
      isButtonLoad,
      bulkInsertState,
      keyTableData,
      selectedKeyTable,
      isPaginationDisabled,
      isPaginationReset,
      bulkEditInputFormData,
      rationale,
      modes,
      dynamicTable,
      userRoles,
      isEmptyReset,
      validation,
      filteredColumn,
      headcellData,
      isdataFiltered,
      isTableDataLoading,
    } = this.state;
    return (
      <>
        <SnackbarAlert
          alertType={alertType}
          open={openAlert}
          message={alertMsg}
          onClose={() => {
            this.setState({ openAlert: false });
          }}
        />
        <Box pb={4}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "25px 10px",
            }}
          >
            <PageHeader
              label={`SDM - ${selectedKeyTable ? selectedKeyTable : selectedSdmTableValue
                }`}
              style={{
                fontSize: "22px",
              }}
            />
            {userRoles.includes("PUBLICATION_LEAD") ||
              userRoles.includes("DATA_ANALYST") ||
              userRoles.includes("MANAGEMENT_LEADERSHIP") ||
              userRoles.includes("SUPER_USER") ? (
              <Box style={{ display: "flex", gap: "1em" }}>
                <GradientButton
                  id="button"
                  label="Upload Files"
                  onClick={() => {
                    this.onClickButton("upload");
                  }}
                  startIcon={<PublishIcon />}
                />
              </Box>
            ) : (
              <></>
            )}
          </Box>
          <CardContainer>
            {isSDMListLoading ? (
              <Box
                style={{
                  justifyContent: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "flex",
                  margin: "30vh",
                }}
              >
                <CircularProgress sx={{ color: "#d52b1e" }} />
              </Box>
            ) : (
              <>
                <Grid container p={2} flexDirection="row">
                  <Grid container item columnSpacing={2}>
                    <Grid item xs={2.5}>
                      <InputLabel> Key Tables</InputLabel>
                      <SingleSelect
                        id="keytable"
                        customOptions="None"
                        defaultValue="None"
                        value={selectedKeyTable}
                        values={keyTableData}
                        onChange={this.handleKeyTableSelect}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4.9}
                      mt={2.5}
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Link
                        style={{
                          color:
                            (tblData.length > 0 &&
                              tblData[0][uniqueColumnValue] === null) ||
                              this.state.selectedSdmTable.length >= 1 ||
                              selectedEntry
                              ? "#707070"
                              : "#0577A3",
                          cursor:
                            (tblData.length > 0 &&
                              tblData[0][uniqueColumnValue] === null) ||
                              this.state.selectedSdmTable.length >= 1 ||
                              selectedEntry
                              ? "default"
                              : "pointer",
                          textDecoration: "none",
                          marginTop: "10px",
                          display: "block",
                        }}
                        onClick={
                          (tblData.length > 0 &&
                            tblData[0][uniqueColumnValue] === null) ||
                            this.state.selectedSdmTable.length >= 1 ||
                            selectedEntry
                            ? () => { }
                            : this.handleFilterColClick
                        }
                      >
                        <FilterListIcon
                          style={{
                            marginTop: "1px",
                            float: "left",
                            marginRight: "3px",
                          }}
                        />
                        Filter by Columns
                      </Link>
                      <Popover
                        open={
                          tblData.length > 0 &&
                            tblData[0][uniqueColumnValue] === null
                            ? false
                            : this.state.isOpenFilterByCol
                        }
                        anchorReference="anchorPosition"
                        anchorPosition={{ top: 150, left: 835 }}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <FilterColumns
                          handleFilterColClick={this.handleFilterColClick}
                          onToggleCheckbox={this.onToggleCheckbox}
                          onDoneClick={this.onDoneClick}
                          onResetClick={this.onResetClick}
                          filterCol={this.state.filterCol}
                          handleSelectAll={this.handleSelectAll}
                        />
                      </Popover>
                    </Grid>
                    <Grid
                      item
                      xs={2.5}
                      mt={2.5}
                      display="flex"
                      justifyContent="center"
                    >
                      <Link
                        style={{
                          color:
                            selectedSdmTableValue === "RPT_OVERRIDES_ARCHIVE" &&
                              (selectedKeyTable === "" ||
                                selectedKeyTable === "RPT_OVERRIDES_ARCHIVE")
                              ? "#707070"
                              : "#0577A3",
                          cursor:
                            selectedSdmTableValue === "RPT_OVERRIDES_ARCHIVE" &&
                              (selectedKeyTable === "" ||
                                selectedKeyTable === "RPT_OVERRIDES_ARCHIVE")
                              ? "default"
                              : "pointer",
                          textDecoration: "none",
                          marginTop: "10px",
                          display: "block",
                        }}
                        onClick={
                          selectedSdmTableValue === "RPT_OVERRIDES_ARCHIVE" &&
                            (selectedKeyTable === "" ||
                              selectedKeyTable === "RPT_OVERRIDES_ARCHIVE")
                            ? () => { }
                            : this.handleTemplateDownload
                        }
                      >
                        <ArrowDownwardIcon
                          style={{
                            marginTop: "1px",
                            float: "left",
                            marginRight: "3px",
                          }}
                        />
                        Download Template
                      </Link>
                    </Grid>
                    <Grid
                      className="EntryDropdown"
                      item
                      justifyContent="flex-end"
                      xs={2}
                      mt={2.9}
                      textAlign={"center"}
                    >
                      <SingleSelect
                        isDisabled={
                          this.state.selectedSdmTable.length >= 1 ||
                            mode === "filter" ||
                            selectedEntry ||
                            filterCol.filter((el) => el.checked === true)
                              .length >= 1
                            ? true
                            : false ||
                            (selectedSdmTableValue ===
                              "RPT_OVERRIDES_ARCHIVE" &&
                              (selectedKeyTable === "" ||
                                selectedKeyTable === "RPT_OVERRIDES_ARCHIVE"))
                        }
                        customOptions="+ New Entry"
                        values={[
                          {
                            text: "Insert Single Entry",
                            value: "Insert Single Entry",
                          },
                          {
                            text: "Insert Multiple Entries",
                            value: "Insert Multiple Entries",
                          },
                        ]}
                        value={selectedEntry}
                        size="small"
                        onChange={this.handleEntryChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <CardContainer>
                  {mode === "clone" ||
                    mode === "edit" ||
                    mode === "comment" ? null : (
                    <>
                      {this.state.selectedSdmTable.length > 1 ? (
                        <>
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            p={3}
                          >
                            <IconButton
                              IconType="Edit"
                              id=""
                              onClick={this.handleEditClick}
                              style={{
                                marginRight: "10px",
                              }}
                            />
                            <IconButton
                              IconType="Delete"
                              id=""
                              onClick={() =>
                                this.setState({ showDeleteDialoge: true })
                              }
                              style={{
                                marginRight: "10px",
                              }}
                            />
                          </Grid>
                        </>
                      ) : (
                        <>
                          {this.state.selectedSdmTable.length === 1 ? (
                            <Grid
                              container
                              direction="row"
                              alignItems="center"
                              p={3}
                            >
                              <IconButton
                                IconType="Edit"
                                id=""
                                onClick={() => this.handleToolbarIcons("edit")}
                                style={{
                                  marginRight: "10px",
                                }}
                              />
                              <IconButton
                                IconType="Delete"
                                id=""
                                onClick={() =>
                                  this.setState({ showDeleteDialoge: true })
                                }
                                style={{
                                  marginRight: "10px",
                                }}
                              />

                              <IconButton
                                IconType="Clone"
                                id=""
                                onClick={this.handleClone}
                                style={{
                                  marginRight: "10px",
                                }}
                              />
                              <IconButton
                                IconType="Comments"
                                id=""
                                onClick={() => {
                                  this.handleToolbarIcons("comment");
                                }}
                                style={{
                                  marginRight: "10px",
                                }}
                              />
                            </Grid>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {isLoading ? (
                    <></>
                  ) : (
                    <>
                      {headcellData.map((el) => {
                        if (el === filteredColumn?.column && isdataFiltered) {
                          return (
                            <Chip
                              key={el}
                              label={el}
                              sx={{
                                "& .MuiChip-deleteIcon": {
                                  color: "#ffffff!important",
                                },
                              }}
                              style={{
                                background:
                                  "linear-gradient(to right, #D52B1E,#78105B)",
                                borderColor: "#D32A1F",
                                color: "#ffffff",
                                textTransform: "capitalize",
                                position: "sticky",
                                left: "30px",
                                zIndex: "99999",
                                marginTop: "2px",
                                marginBottom: "2px",
                              }}
                              variant="outlined"
                              onDelete={this.handleRemoveFilter}
                            />
                          );
                        } else {
                          <></>;
                        }
                        return null;
                      })}
                    </>
                  )}

                  <DataLoader
                    handleSorting={this.handleSorting}
                    isTableDataLoading={isTableDataLoading}
                    validation={validation}
                    getSearchFilterTableData={this.getSearchFilterTableData}
                    isEmptyReset={isEmptyReset}
                    isPaginationReset={isPaginationReset}
                    isPaginationDisabled={isPaginationDisabled}
                    rationale={rationale}
                    onRationaleInputChange={this.onRationaleInputChange}
                    selectedSdmTableValue={
                      selectedKeyTable
                        ? selectedKeyTable
                        : selectedSdmTableValue
                    }
                    handleDelete={this.handleDelete}
                    onClickLink={this.onClickLink}
                    handleRemoveClick={this.handleRemoveClick}
                    handleAddMoreField={this.handleAddMoreField}
                    isButtonLoad={isButtonLoad}
                    handleSave={this.handleSaveRecord}
                    handleMode={this.handleToolbarIcons}
                    handleActionEditClick={this.handleEditClick}
                    handleClone={this.handleClone}
                    handleBulkInsertInputChange={
                      this.handleBulkInsertInputChange
                    }
                    bulkInsertState={bulkInsertState}
                    handleApply={this.handleApply}
                    handleReset={this.handleFieldReset}
                    bulkEditInputFormData={bulkEditInputFormData}
                    handleBulkInputChange={this.handleBulkInputChange}
                    columnDetails={columnDetails}
                    checkBoxDifferentiator={uniqueColumnValue.toLowerCase()}
                    isLoading={isLoading}
                    onClickCheckBox={this.onClickCheckBox}
                    actionArray={["ActionMenu"]}
                    onActionClick={this.onActionClick}
                    onChangePage={this.onChangePage}
                    onChangeRow={this.onChangeRow}
                      updateRows={this.updateRows}
                    tableData={
                      filterCol.filter((el) => el.checked).length
                        ? dynamicTable
                        : tblData
                    }
                    totalRecordsCount={totalRecordCount}
                    mode={mode}
                    modes={modes}
                    handleToolbarCancel={this.handleToolbarCancel}
                    commentId={this.props.commentId}
                    handleInputChange={this.handleInputChange}
                    fetchFilterData={this.fetchFilterData}
                    isdataFiltered={this.state.isdataFiltered}
                    filteredColumn={filteredColumn}
                    page={this.state.page}
                    rows={this.state.rows}
                    allColumnDetails={this.state.AllColumnDetails}
                  />
                </CardContainer>
              </>
            )}
          </CardContainer>
          <ModalDialog
            isOpen={this.state.showDeleteDialoge}
            title="Delete Record"
            onClose={() => this.setState({ showDeleteDialoge: false })}
            dialogWidth="sm"
          >
            <Typography>Are you sure you want to delete ?</Typography>
            <Grid item container mt={2.5}>
              <Grid item>
                <RedOutlinedButton
                  label="No"
                  onClick={() => this.setState({ showDeleteDialoge: false })}
                />
              </Grid>
              <Grid item ml={1.5}>
                <GradientButton
                  isButtonLoad={isButtonLoad}
                  label="Yes"
                  onClick={this.handleDelete}
                />
              </Grid>
            </Grid>
          </ModalDialog>
        </Box>
      </>
    );
  }
}

export default withRouter(SdmChanges);
